/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('a[href*=\\#]').on('click', function(event){     
            event.preventDefault();
            $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
        });

        $.fn.pressEnter = function(fn) {  

          return this.each(function() {  
              $(this).bind('enterPress', fn);
              $(this).keyup(function(e){
                  if(e.keyCode == 13)
                  {
                    $(this).trigger("enterPress");
                  }
              })
          });  
       }; 

      var fixedNav = function() {
        if ($(window).scrollTop() > 50) {
          $('header.banner').addClass('scrolled');
        }
        else {
          $('header.banner').removeClass('scrolled');
        }
      }

      $('.img-colorbox').colorbox({
        maxWidth: '70%',
        maxHeight: '70%',
        previous: '<i class="fa fa-angle-left" aria-hidden="true"></i><span class="sr-only">Previous</span>',
        next: '<i class="fa fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>',
        close: '<i class="fa fa-times" aria-hidden="true"></i>'
      });

       fixedNav();

       $(window).scroll(fixedNav);

       $('.blocks').packery({
         // options
         itemSelector: '.block',
         gutter: 0
       });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {     

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'blog': {
      init: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
